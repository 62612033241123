/**
 * @module api-web:filter-opts/data
 * @description common apis : dropdown select options - data
 */
import service from '@/service'

/**
 * @param {*} params
 * @description 公司组织 ID
 */
export function getCompanyOpts(params) {
  return service['api-web']({
    url: '/public/data/getCompanyPullOnList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {*} params
 * @description 公司组织 名称
 */
export function getCompanyNameOpts(params) {
  return getCompanyOpts(params)
}
/**
 * @param {*} params
 * @description 仓库名称 下拉
 */
export function getWarehouseNameListOpts(params) {
  return service['api-web']({
    url: '/public/data/getWarehouseDropDown',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {*} params
 * @description 仓库名称 下拉
 */
export function getWarehouseNameListOptsNew(params) {
  return service['api-web']({
    url: '/public/data/getAllStatusWarehouseDropDown',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 客户分类
 */
export function getCustomerCategoryOpts(params) {
  return service['api-web']({
    url: '/public/data/getListTree',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 销售人员
 */
export function getSellUserOpts(params) {
  return service['api-web']({
    url: '/public/data/getSalesPersonnel',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 销售人员(上面那个暂时已废弃)
 */
export function getSalesDeptUserOpts(params) {
  return service['api-web']({
    url: '/public/data/getSalesDeptUser',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/json ' }
  })
}

/* 销售部 */
export function getSalesDeptOpts(params) {
  return service['api-web']({
    url: '/public/data/getSalesDeptUser',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/json ' }
  })
}

/**
 * 供应商采购员
 */
export function getSupplierBuyerOpts(params) {
  return service['api-web']({
    url: '/public/data/getPurchaseDept',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 供应商管理专员
 */
export function getManagementSpecialistOpts(params) {
  return service['api-web']({
    url: '/public/data/getSupplierAttach',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 税收制度
 */
export function getTaxSystemCodeOpts(params) {
  return service['api-web']({
    url: '/public/data/getTaxSystemPullList',
    method: 'get',
    params
  })
}

/**
 * 税种
 */
export function getTaxTypeCodeOpts(params) {
  return service['api-web']({
    url: '/public/data/getTaxTypePullList',
    method: 'get',
    params
  })
}

/**
 * 库存状态类型
 */
export function getWarehouseStatusTypeOpts(params) {
  return service['api-web']({
    url: '/public/data/getWarehouseStatusPullList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 根据结算组织ID查询结算方式下拉
 */
export function getAsyncClearingFormOpts(params) {
  return service['api-web']({
    url: '/public/data/getClearingForm',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 根据多个结算组织查询结算方式下拉
 */
export function getAsyncMutipileClearingFormOpts(params) {
  return service['api-web']({
    url: '/public/data/getClearingFormByIds',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 组织(供应商，客户，物流商)的结算方式和结算组织联动的结算方式的并集
 * @param {{id:(string|number),orgCode:string,orgType:number}} params
 * @description
 * - id      结算组织Id
 * - orgCode 组织 code
 * - orgType 组织类型
 *    - 0供应商
 *    - 1客户
 *    - 2物流商
 */
export function getClearingFormUnionOpts(params) {
  return service['api-web']({
    url: '/public/data/getClearingFormByOrganizationUnion',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 异步获取对应组织下拉框(货主)  (财务付款组织，结算组织都用这个，与祥威确定）
 */
export function getCompanyInfoOpts(params) {
  return service['api-web']({
    url: '/public/data/getCompanyInfo',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 异步获取对应组织下拉框(货主)  (财务付款组织，结算组织都用这个，与祥威确定）
 */
export function getCompanyInfoForEnableOpts(params) {
  return service['api-web']({
    url: '/public/data/getCompanyInfoForEnable',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 获取仓库下拉框(新疆仓部门过滤) CODE
 */
export function getWarehouseOpts(params) {
  return service['api-web']({
    url: '/public/data/getWarehouse',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 获取仓库下拉框(新疆仓部门过滤) ID
 */
export function getWarehouseIdOpts(params) {
  return getWarehouseOpts(params)
}
/**
 * 获取仓库下拉框(新疆仓部门过滤) NAME
 */
export function getWarehouseNameOpts(params) {
  return getWarehouseOpts(params)
}

/**
 * 仓库（所有启用已审核的仓库）
 */
export function getWarehouseAllOnOpts(params) {
  return service['api-web']({
    url: '/public/data/getAllWarehouseDropDown',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 *  仓库 所有启用已审核的仓库 排除供应商直发仓，供应商库存直发仓
 */
export function getWarehouseExcludeDirectOpts(params) {
  return service['api-web']({
    url: '/public/data/getExcludeDirectWarehouseDropDown',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 商品不可售地域 下拉框
 */
export function getSellTerritoryOpts(params) {
  return service['api-web']({
    url: '/public/data/getRegion',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 供应商分组 下拉树
 */
export function getSupplierGroupOpts(params) {
  return service['api-web']({
    url: '/public/data/getSupplierGroups',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 店铺模糊查询下拉框（下拉公用）
 */
export function getStorePullOpts(params) {
  return service['api-web']({
    url: '/public/data/getStorePull',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 税收制度地区下拉框
 */
export function getDistrictOpts(params) {
  return service['api-web']({
    url: '/public/data/getRegion',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 根据结算方式id查询我方账户信息
 */
export function getCompanyInfoFinance(params) {
  return service['api-web']({
    url: '/public/data/companyInfoFinance/info',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 费用项目
 */
export function getFeeItemOpts(params) {
  return service['api-web']({
    url: '/public/fin/getCostItem',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{companyId:string}} params
 * @description 获取组织对应的财务下拉框
 */
export function getCompanyInfoFinanceOpts(params) {
  return service['api-web']({
    url: '/public/data/getCompanyInfoFinance',
    method: 'get',
    params
  })
}

/**
 *
 * @param {{isMonthly?:(0|1|null),type:(0|1)}} params
 * @description  账期备注下拉接口
 * - isMonthly 是否账期，0为预付，1为月结, null全部
 * - type 区分 0收款、1付款
 */
export function getPaymentDaysNoteOpts(params) {
  return service['api-web']({
    url: '/public/data/basic/paymentDaysNote/getPaymentDaysNote',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 仓库（所有所有非创建状态的仓库）
 */
export function getNotCreatedWarehouseOpts(params) {
  return service['api-web']({
    url: '/allPublic/getAllNonCreatedWarehouseNames',
    method: 'get',
    params,
    crypt: { type: 'none' }
  })
}
